import { createHashRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/Layout";
import PageEntry from "./pages/Entry";
import ErrorPage from "./pages/NotFound";
import PageSubmission from "./pages/Submission";

const router = createHashRouter([
  {
    path: "",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/entry",
        element: <PageEntry />,
      },
      {
        path: "/submission",
        element: <PageSubmission />,
      },
      // {
      //   path: "/download",
      //   element: <PageDownload />,
      // },
      // {
      //   path: "/assess",
      //   element: <PageAssess />,
      // },
    ],
  },
]);

export const Router = () => {
  return <RouterProvider router={router} />;
};
