import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
export const theme = extendTheme(
  {
    styles: {
      global: {
        "*:focus-visible": {
          outline: "none",
          boxShadow: "none",
        },
        "*[data-focus-visible]": {
          outline: "none",
          boxShadow: "none",
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "purple" }),
);
