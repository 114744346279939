import { toast } from "@/components/base/Toast";
import { Dictionary, ServerErrorResponse } from "@/types/base";
import events from "@/utils/events";
import { LoginEvent } from "@/utils/hooks/useLogin";

type ErrorResponse = {
  status: number;
  code?: string;
  msg?: string;
};
type SuccessResponse<T> = { data: T };
export type _Response<T = Dictionary> = SuccessResponse<T>;

export interface FetchOpts<T = any> {
  qs?: Dictionary<T>;
  json?: Dictionary<T>;
  qsFormat?: "comma" | "repeat";
  qsStringifyIndices?: boolean;
  form?: Dictionary<T>;
  method?: "POST" | "GET" | "DELETE" | "OPTION" | "PUT";
  formData?: Dictionary<T>;
  // timeout?: number;
  credentials?: "same-origin" | "include" | "omit";
  headers?: Dictionary;
  baseURL?: string;
  mode?: "cors" | "no-cors" | "same-origin";
}

function fetchRequest<T = any>(url: string, opts: FetchOpts = {}): Promise<_Response<T>> {
  const timeout = 10000;
  const _fetchOpts: RequestInit = {
    credentials: "include",
    method: "GET",
    headers: {
      // Authorization: localStorage.getItem(STORAGE_USER_ID_KEY) || "",
    },
  };
  if (opts.qs) {
    url += (url.indexOf("?") === -1 ? "?" : "&") + encodeQueryString(opts.qs, opts.qsFormat);
  }
  if (opts.json) {
    Object.assign(_fetchOpts.headers!, { "Content-Type": "application/json" });
    _fetchOpts.body = JSON.stringify(opts.json);
  }
  if (opts.form) {
    Object.assign(_fetchOpts.headers!, { "Content-Type": "application/x-www-form-urlencoded" });

    _fetchOpts.body = encodeQueryString(opts.form);
  }
  if (opts.method) {
    _fetchOpts.method = opts.method;
  }
  if (opts.formData) {
    const form = new FormData();
    for (const key in opts.formData) {
      form.append(key, opts.formData[key]);
    }
    _fetchOpts.body = form;
  }
  // if (opts.timeout !== undefined) {
  //   timeout = opts.timeout;
  // }
  if (opts.credentials) {
    _fetchOpts.credentials = opts.credentials;
  }
  /* top level priority varibale set in the end */
  if (opts.headers) {
    Object.assign(_fetchOpts.headers!, opts.headers);
  }

  if (opts.mode) {
    _fetchOpts.mode = opts.mode;
  }

  const baseURL = opts.baseURL || "";
  return fetch(baseURL + url, _fetchOpts)
    .then((response) => checkStatus<T>(response as Response))
    .catch(handleError);
}

const isEmpty = (o: any) => o === undefined || o === null;

/**
/**
 * comma
 * repeat
 */
const stringifyArray = (key: string, value: any[], format = "comma"): string => {
  if (value.length > 0) {
    if (format === "comma") {
      return key + "=" + value.join(",");
    }
    if (format === "repeat") {
      const stringArr = value.map((item, index) =>
        index === value.length - 1 ? `${key}=${item}` : `${key}=${item}&`,
      );
      return stringArr.join("");
    }
  }

  return key + "=";
};

export const encodeQueryString = (obj: Dictionary, format = "comma") => {
  const pairs: string[] = [];
  Object.keys(obj).forEach((key) => {
    if (!isEmpty(obj[key])) {
      if (Array.isArray(obj[key])) {
        pairs.push(stringifyArray(key, obj[key], format));
        return;
      }

      pairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  });
  return pairs.join("&");
};
/**
 * 检查接口响应状态码
 *
 * @param {Object} response fetch返回的响应对象
 * @return {Object} 状态码正常时返回响应本身，否则返回 reject 信息
 */
function checkStatus<T>(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    if (response.headers.get("Content-Type")?.includes("application/json")) {
      return response.json().then((json) => {
        const successResponse: SuccessResponse<T> = {
          data: json,
        };
        return Promise.resolve(successResponse);
      });
    } else {
      const successResponse: SuccessResponse<any> = {
        data: null,
      };
      return Promise.resolve(successResponse);
    }
  } else {
    if (response.status === 401) {
      events.emit(LoginEvent.Logout);
    }
    return Promise.reject(response);
  }
}

/**
 * 异常处理函数，包含错误提示
 *
 * @param {Object} e 错误信息
 */
function handleError(e: Response | "timeout") {
  if (e !== "timeout") {
    const responseStatus = e?.status;
    if (e.headers.get("Content-Type")?.includes("application/json")) {
      return e.json().then((response?: ServerErrorResponse) => {
        return Promise.reject({
          status: responseStatus,
          code: response?.error_code,
          msg: response?.error_message,
        });
      });
    } else {
      console.error("request Error", e);
      return Promise.reject({
        status: responseStatus,
        code: responseStatus ?? -1,
        msg: e?.statusText ?? null,
      });
    }
  } else {
    console.error("请求错误提示", { status: "", msg: "网络加载失败，请检查网络设置" });
    return Promise.reject({ status: "", msg: "网络加载失败，请检查网络设置" });
  }
}

function timeoutFetch(fetchPromise: Promise<Response>, timeout: number) {
  let abortFn: (() => void) | null = null;
  const abortPromise = new Promise(function (resolve, reject) {
    abortFn = function () {
      reject("timeout");
    };
  });
  const abortablePromise = Promise.race([fetchPromise, abortPromise]);

  setTimeout(function () {
    abortFn?.();
  }, timeout);

  return abortablePromise;
}

export { fetchRequest as superFetch };

export interface ParseResponseOpts {
  showNotification?: boolean;
}

export const request = <T = any>(
  url: string,
  fetchOpts?: FetchOpts,
  parseOpts?: ParseResponseOpts,
) =>
  fetchRequest<T>(url, {
    ...fetchOpts,
  })
    .then((res) => parseResponse<T>(res, parseOpts))
    .catch((err) => parseErrorResponse(err, parseOpts));

export const parseResponse = <T = any>(
  response: _Response<T>,
  opts: ParseResponseOpts = { showNotification: true },
) => {
  return response;
};

export const parseErrorResponse = (
  err: ErrorResponse,
  opts: ParseResponseOpts = { showNotification: true },
) => {
  const { showNotification } = opts;

  if (showNotification) {
    toast({
      title: err.msg ?? "抱歉，服务出了一些问题",
      status: "error",
      position: "top",
      duration: 4000,
    });
  }
  return Promise.reject(err);
};
