import { WithClassName } from "@/types/base";
import { Center, chakra, Flex } from "@chakra-ui/react";
import React, { PropsWithChildren, useEffect } from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { LoginEvent, useLogin } from "@/utils/hooks/useLogin";
import events from "@/utils/events";
import { getAuthorization } from "@/requests/api";

const Layout: React.FC<PropsWithChildren<WithClassName>> = ({ className }) => {
  const { isLogin } = useLogin();
  const isEntry = useMatch("/entry");
  const navigate = useNavigate();
  const isRootPage = useMatch("/");

  const fetchAuth = () => {
    // 调用获取登录信息接口
    getAuthorization()
      .then(() => {
        events.emit(LoginEvent.Login);
      })
      .catch(() => {
        events.emit(LoginEvent.Logout);
      });
  };

  useEffect(() => {
    fetchAuth();
  }, []);

  useEffect(() => {
    if (isRootPage) {
      backToAuth();
    }
  }, [isRootPage]);

  useEffect(() => {
    if (!isLogin && !isEntry) {
      backToAuth();
    }
  }, [isLogin]);

  function backToAuth() {
    navigate("/entry");
  }
  return (
    <Center h="100%" pt="186px" className={className} pos="relative">
      <Flex flexDir="column" w="500px">
        <Outlet />
        {/* {children} */}
      </Flex>
    </Center>
  );
};
export default chakra(Layout);
