import { request } from "./super-fetch";

const API_MAP = {
  auth: "/api/authentication",
  homeworks: "/api/homeworks",
  assess: "/api/homeworks/correction",
};

export const getAuthorization = () => {
  return request(API_MAP.auth, {
    method: "GET",
  });
};

export const postAuthorization = (data: { id: string }) => {
  return request(API_MAP.auth, {
    method: "POST",
    json: data,
  });
};

export const postHomeworks = (data: { date: string; files: File }) => {
  return request(API_MAP.homeworks, {
    method: "POST",
    formData: data,
  });
};

export const getHomeworks = (date: string) => {
  return request<{
    urls: string; // 下载地址
  }>(API_MAP.homeworks, {
    method: "GET",
    qs: { date },
  });
};

export const postHomeworksAssessment = (data: { file_name: string; comment: string }) => {
  return request(API_MAP.assess, {
    method: "POST",
    json: data,
  });
};
