import { Box, Center } from "@chakra-ui/react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Center id="error-page" flexDirection="column" h="90vh">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <Box mt="20px">
        <i>404 | The page is not found</i>
      </Box>
    </Center>
  );
}
