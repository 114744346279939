import { WithClassName } from "@/types/base";
import Upload from "rc-upload";
import { RcFile } from "rc-upload/lib/interface";
import React, { PropsWithChildren } from "react";

type UploaderProps = {
  onChange?: (file: RcFile, FileList: RcFile[]) => void;
};
const Uploader: React.FC<PropsWithChildren<WithClassName<UploaderProps>>> = ({
  className,
  children,
  onChange,
}) => {
  return (
    <Upload
      className={className}
      draggable
      beforeUpload={(file, FileList) => {
        console.log("file: ", file);
        onChange?.(file, FileList);
        return false;
      }}
    >
      {children}
    </Upload>
  );
};

export default Uploader;
