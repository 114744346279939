import { toast } from "@/components/base/Toast";
import DeleteIcon from "@/components/DeleteIcon";
import Uploader from "@/components/Uploader";
import { postHomeworks } from "@/requests/api";
import { formatTime } from "@/utils/format-time";
import { Button, Flex, Heading, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";

const PageSubmission: React.FC = () => {
  const [date, setDate] = useState(formatTime(new Date().getTime(), "YYYY-MM-DD"));
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);

  function handleSubmit() {
    if (uploading) return;
    if (!file) {
      toast({
        status: "error",
        title: "请上传文件",
      });
      return;
    }
    setUploading(true);

    postHomeworks({
      date,
      files: file,
    })
      .then(() => {
        toast({
          title: "提交成功！",
          status: "success",
        });
      })
      .finally(() => {
        setUploading(false);
      });
  }
  return (
    <>
      <Heading size="sm" as="h6" ml="4px">
        选择日期：
      </Heading>
      <Input
        variant="filled"
        mt="8px"
        type="date"
        value={date}
        onChange={(e) => {
          setDate(e.target.value);
        }}
      />
      <Flex alignItems="center" mt="16px">
        <Uploader
          onChange={(file) => {
            setFile(file);
          }}
        >
          <Button w="300px" h="300px" colorScheme="gray" flexDir="column" alignItems="center">
            <Text>上传文件</Text>
            <Text color="gray.600" fontSize="sm" mt="10px" fontWeight="400">
              可点击此处或拖拽文件
            </Text>
          </Button>
        </Uploader>
        <Text ml="12px">{file?.name}</Text>
        {file && (
          <DeleteIcon
            ml="12px"
            fontSize="18px"
            onClick={() => {
              setFile(null);
            }}
          />
        )}
      </Flex>
      <Button
        alignSelf="center"
        mt="40px"
        variant="solid"
        w="200px"
        onClick={handleSubmit}
        isLoading={uploading}
        loadingText="上传中..."
        isDisabled={uploading}
      >
        提交
      </Button>
    </>
  );
};

export default PageSubmission;
