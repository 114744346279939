import { genBlock } from "@/utils/bem";
import { Button, Stack } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import React from "react";
import { useNavigate } from "react-router-dom";

const block = genBlock("p-select-next");

const SelectNext: React.FC = () => {
  const navigate = useNavigate();

  function navToSubmission() {
    navigate("/submission");
  }

  return (
    <Stack className={block()} spacing="10px">
      <Button
        onClick={navToSubmission}
        justifyContent="flex-start"
        rounded="0px"
        w="100%"
        rightIcon={<ArrowForwardIcon />}
        size="lg"
      >
        提交作业
      </Button>
    </Stack>
  );
};

export default SelectNext;
