import { STORAGE_USER_ID_KEY } from "@/constants/storage";
import { useEffect, useState } from "react";
import { matchPath, useNavigate } from "react-router-dom";
import events from "../events";

export enum LoginEvent {
  Login = "login",
  Logout = "logout",
}
export function useLogin() {
  // const [userId, setUserId] = useState(""); // 不一定有这个字段
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  // const updateLocalStorageUserId = (userId: string) => {
  //   localStorage.setItem(STORAGE_USER_ID_KEY, userId);
  //   setLocalUserId(localStorage.getItem(STORAGE_USER_ID_KEY) ?? "");
  // };

  function onLogin() {
    setIsLogin(true);
  }

  function onLogout() {
    if (!matchPath({ path: "/entry" }, window.location.pathname)) {
      navigate("/entry", { replace: true });
    }
    setIsLogin(false);
  }

  useEffect(() => {
    events.on(LoginEvent.Login, onLogin);
    events.on(LoginEvent.Logout, onLogout);

    return () => {
      events.off(LoginEvent.Login, onLogin);
      events.off(LoginEvent.Logout, onLogout);
    };
  }, []);

  return {
    isLogin,
    // userId,
  };
}
