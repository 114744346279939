import AdminEntry from "@/components/SelectNext";
import { Button, Heading, Input } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { LoginEvent, useLogin } from "@/utils/hooks/useLogin";
import events from "@/utils/events";
import { postAuthorization } from "@/requests/api";
import { useNavigate } from "react-router-dom";

// const block = genBlock("p-entry");
const PageEntry: React.FC = () => {
  const { isLogin } = useLogin();
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      navigate("/submission");
    }
  }, [isLogin]);

  function handleAuth() {
    if (!userId) {
      return;
    }
    // auth
    postAuthorization({
      id: userId,
    }).then(() => {
      events.emit(LoginEvent.Login, {
        type: LoginEvent.Login,
      });
    });
  }
  return (
    <>
      {isLogin ? (
        <AdminEntry />
      ) : (
        <>
          <Heading size="md" as="h5">
            输入你的ID：
          </Heading>
          <Input
            variant="filled"
            mt="8px"
            colorScheme="red"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <Button alignSelf="center" mt="40px" variant="solid" w="200px" onClick={handleAuth}>
            登录
          </Button>
        </>
      )}
    </>
  );
};

export default PageEntry;
