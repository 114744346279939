import React from "react";
import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import { Router } from "./router";
import { theme } from "./theme";
import { ToastContainer } from "./components/base/Toast";

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router />
      <ToastContainer />
    </ChakraProvider>
  );
};

export default App;
