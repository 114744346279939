import { theme } from "@/theme";
import { createStandaloneToast } from "@chakra-ui/toast";

const { ToastContainer, toast } = createStandaloneToast({
  defaultOptions: {
    position: "top",
    status: "success",
    duration: 3000,
  },
});

export { toast, ToastContainer };
