import React from "react";
import { Box, chakra, Icon } from "@chakra-ui/react";
import { WithClassName } from "@/types/base";
const DeleteIcon: React.FC<WithClassName<{ onClick?: () => void }>> = ({ onClick, className }) => {
  return (
    <Box
      lineHeight="inherit"
      className={className}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
    >
      <Icon
        width="1em"
        height="1em"
        cursor="pointer"
        viewBox="0 0 14 14"
        fill="none"
        onClick={onClick}
      >
        <path
          d="M0.666687 2.66634H2.50002M2.50002 2.66634V12.333C2.50002 12.4214 2.53514 12.5062 2.59765 12.5687C2.66016 12.6312 2.74495 12.6663 2.83335 12.6663H11.1667C11.2551 12.6663 11.3399 12.6312 11.4024 12.5687C11.4649 12.5062 11.5 12.4214 11.5 12.333V2.66634M2.50002 2.66634H11.5M2.50002 2.66634H4.33335M11.5 2.66634H13.3334M11.5 2.66634H9.66669M4.33335 2.66634V1.33301H9.66669V2.66634M4.33335 2.66634H9.66669M5.66669 4.99967V9.99967M8.33335 4.99967V9.99967"
          stroke="#5F5F5F"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Icon>
    </Box>
  );
};

export default chakra(DeleteIcon);
