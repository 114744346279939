/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/ban-ts-comment */
export type EventType = any;
export type EventCallback = (e?: EventInfo) => void;

export function toArray<T>(list: any, start?: number): Array<T> {
  start = start || 0;
  let i = list.length - start;
  const ret: Array<T> = new Array(i);
  while (i--) {
    ret[i] = list[i + start];
  }
  return ret;
}
export interface EventInfo {
  type: EventType;
  data?: {
    [k: string]: any;
  };
}
export class Events {
  private static instance: Events;
  private readonly _events: {
    [key: string]: EventCallback[] | null;
  };

  constructor() {
    this._events = {};
  }

  // 通过getInstance获取唯一实例
  public static getInstance() {
    if (!Events.instance) Events.instance = new Events();
    return Events.instance;
  }

  // 绑定事件
  public on(event: EventType | Array<EventType>, fn: EventCallback): void {
    if (Array.isArray(event)) {
      for (let i = 0, l = event.length; i < l; i++) {
        this.on(event[i], fn);
      }
    } else {
      (this._events[event] || (this._events[event] = [])).push(fn);
    }
  }

  // 一次性绑定事件
  public once(event: EventType | Array<EventType>, fn: EventCallback): void {
    function on() {
      console.log("arguments: ", arguments);
      Events.instance.off(event, on);
      //@ts-ignore
      fn.apply(Events.instance, ...arguments);
    }
    on.fn = fn;
    this.on(event, on);
  }

  // 触发事件
  public emit(event: EventType, payload?: EventInfo) {
    let cbs = this._events[event];
    if (cbs) {
      cbs = cbs.length > 1 ? toArray(cbs) : cbs;
      const args: any = toArray(arguments, 1);
      for (let i = 0, l = cbs.length; i < l; i++) {
        cbs![i].apply(this, args);
      }
    }
  }

  // 解绑事件
  public off(event: EventType | Array<EventType>, fn?: EventCallback): void {
    if (Array.isArray(event)) {
      for (let i = 0, l = event.length; i < l; i++) {
        this.off(event[i], fn);
      }
    } else {
      const cbs = this._events[event];
      if (!cbs) return;
      if (!fn) {
        this._events[event] = null;
      } else {
        let cb: any;
        let len = cbs.length;
        // 遍历移除相应回调
        while (len--) {
          cb = cbs[len];
          if (cb === fn || cb.fn === fn) {
            cbs.splice(len, 1);
            break;
          }
        }
      }
    }
  }

  // 移除所有事件
  public offAll(): void {
    for (const k in this._events) {
      this._events[k] = null;
    }
  }
}

export default Events.getInstance();
