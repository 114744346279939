export function isDate(time: Date | number) {
  return time instanceof Date;
}

/**
 * 格式化时间
 * @param {Date} time
 * @param {*} pattern eg: YYYY-MM-DD HH:mm:ss
 */
export function formatTime(time: Date | number, pattern = "YYYY-MM-DD HH:mm:ss") {
  // REVIEW 假定传入数据不是Date就是时间戳
  const dateTime: Date = !isDate(time) ? new Date(time) : (time as Date);
  // if (!isDate(time)) {
  //   // REVIEW 假定传入数据不是Date就是时间戳
  //   time = new Date(time);
  // }
  const year = dateTime.getFullYear();
  const month = `00${dateTime.getMonth() + 1}`.slice(-2);
  const day = `00${dateTime.getDate()}`.slice(-2);
  const hour = `00${dateTime.getHours()}`.slice(-2);
  const min = `00${dateTime.getMinutes()}`.slice(-2);
  const sec = `00${dateTime.getSeconds()}`.slice(-2);
  return pattern
    .replace("YYYY", year.toString())
    .replace("MM", month)
    .replace("DD", day)
    .replace("HH", hour)
    .replace("mm", min)
    .replace("ss", sec);
}

/**
 * 把时间数字格式成两位字符
 * 比如 8 -> 08
 * 12 依旧保持 12
 */
export function twoDigitFormat(time: number | string): string {
  return ("00" + time).slice(-2);
}

/**
 * formatDuration
 * @param duration 单位秒
 */
export function formatDuration(duration: number) {
  const s = duration % 60;
  const m = Math.floor(duration / 60) % 60;
  const h = Math.floor(duration / (60 * 60));

  const formatH = h ? `${h}h` : "";
  let formatM = m ? `${m}min` : "";
  let formatS = s ? `${s}s` : "";
  if (m && s) {
    formatM = `${m}.5min`;
    formatS = "";
  }
  const format = `${formatH}${formatM}${formatS}`;
  return format;
}

export function formatToDawn(time: Date | number, incDays = 0): number {
  const dateTime = new Date(time);
  const days = incDays * 86400000;
  return new Date(dateTime.toLocaleDateString()).getTime() + days;
}
